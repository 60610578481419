var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      [
        _vm._m(0),
        _vm._l(_vm.data, function (item, i) {
          return _c("tr", { key: i, staticClass: "body-table-limits" }, [
            _c("td", { staticClass: "normal-text-table" }, [
              _vm._v(_vm._s(item.ModalityGroupName)),
            ]),
            _c("td", { staticClass: "limits-style" }, [
              _vm._v(_vm._s(_vm._f("maskCurrency")(item.LimitRevised))),
            ]),
            _c("td", { staticClass: "limits-style" }, [
              _vm._v(_vm._s(_vm._f("maskCurrency")(item.AvailableLimit))),
            ]),
            _c("td", { staticClass: "normal-text-table" }, [
              item.CanViewLimit
                ? _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(item.RiskRate) +
                        "% / " +
                        _vm._s(item.RiskRate) +
                        "%\n        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        }),
        _c("tr", { staticClass: "total-value-table-limits" }, [
          _c("td", [_vm._v("Limite Total")]),
          _c("td", { staticClass: "limits-style" }, [
            _vm._v(_vm._s(_vm._f("maskCurrency")(_vm.maxApprovedLimit))),
          ]),
          _c("td", { staticClass: "limits-style" }, [
            _vm._v(_vm._s(_vm._f("maskCurrency")(_vm.maxAvailableLimit))),
          ]),
          _c("td"),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Modalidade")]),
      _c("th", [_vm._v("Limite aprovado")]),
      _c("th", [_vm._v("Limite disponível")]),
      _c("th", [_vm._v("Taxa seguro privado/público")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }